import { defineStore } from 'pinia'
import axiosService from '~/services/axios.service.js'

export const useSelfInstructStore = defineStore({
  id: 'self-instruct',
  persist: true,
  state: () => ({
    reviewingQuote: false,
    quoteStored: false,
    defaultFees: {
      vat: 0.2,
      office_copy_entries_land_registry: 3,
      title_plan_land_registry: 3,
      aml_checks: 49.97,
      money_handling_cost: 49,
      archive_fee: 45,
      hmlr_k16: 2,
      hmlr_os1: 2,
      local_authority_search: '£200.00 - £250.00*',
      water_drainage_search: 60,
    },
    form: {
      instructed: null,
      referral_estate_agent_ref: null,
      negotiator_ref: null,
      matter_type_code: null,
      sold_or_not_sold: null,
      self_instruct_or_quote_only: null,
      taken_seller: null,
      free_valuation: null,
      client: {
        address: {
          address_type: 'address_finder',
          full_address: null,
          title_number: null,
          address_line1: null,
          address_line2: null,
          city: null,
          postcode: null,
          postal_code: null,
          uprn: null,
          data: {
            tenure: null,
          },
          address_lines: [],
        },
        title: null,
        name: null,
        surname: null,
        email: null,
        phone_number: null,
        isVisible: true,
        hasNext: false,
      },
      is_client_2: null,
      is_client_2_address_same: null,
      is_client_3_address_same: null,
      is_client_4_address_same: null,
      client_2: {
        title: null,
        name: null,
        surname: null,
        email: null,
        phone_number: null,
        isVisible: false,
        hasNext: false,
        address: {
          address_type: 'address_finder',
          full_address: null,
          title_number: null,
          address_line1: null,
          address_line2: null,
          city: null,
          postcode: null,
          postal_code: null,
          uprn: null,
          data: {
            tenure: null,
          },
          address_lines: [],
        },
      },
      client_3: {
        title: null,
        name: null,
        surname: null,
        email: null,
        phone_number: null,
        isVisible: false,
        hasNext: false,
        address: {
          address_type: 'address_finder',
          full_address: null,
          title_number: null,
          address_line1: null,
          address_line2: null,
          city: null,
          postcode: null,
          postal_code: null,
          uprn: null,
          data: {
            tenure: null,
          },
          address_lines: [],
        },
      },
      client_4: {
        title: null,
        name: null,
        surname: null,
        email: null,
        phone_number: null,
        isVisible: false,
        hasNext: false,
        address: {
          address_type: 'address_finder',
          full_address: null,
          title_number: null,
          address_line1: null,
          address_line2: null,
          city: null,
          postcode: null,
          postal_code: null,
          uprn: null,
          data: {
            tenure: null,
          },
          address_lines: [],
        },
      },
      sale_property: {
        price: null,
        freehold_code: null,
        sale_mortgage: null,
        property_type: null,
        bedrooms: null,
        bathrooms: null,
        finish_quality: null,
        outdoor_space: null,
        parking_space: null,
        address: {
          address_type: 'address_finder',
          full_address: null,
          title_number: null,
          address_line1: null,
          address_line2: null,
          city: null,
          postcode: null,
          uprn: null,
          data: {
            tenure: null,
          },
          address_lines: [],
        },
        address_identical: null,
      },
      purchase_property: {
        price: null,
        freehold_code: null,
        purchase_mortgage: null,
        additional_property: null,
        first_time_buyer: null,
        buying: null,
        address: {
          address_type: 'address_finder',
          full_address: null,
          title_number: null,
          address_line1: null,
          address_line2: null,
          city: null,
          postcode: null,
          uprn: null,
          data: {
            tenure: null,
          },
          address_lines: [],
        },
      },
      terms: {
        terms_accepted: null,
        no_move_no_cost_accepted: null,
        fast_track_to_exchange_accepted: null,
      },
      fees: {
        purchase_fee: null,
        sale_fee: null,
      },
      quick_note: '',
    },
  }),
  actions: {
    async fetchFees() {
      return await axiosService
        .get('/self-instruct/fees', {
          params: {
            matter_type_code: this.form.matter_type_code,
            additional_property: this.form.purchase_property.additional_property,
            first_time_buyer: this.form.purchase_property.first_time_buyer,
            purchase_price: this.form.purchase_property.price,
          },
        })
        .then((response) => {
          this.form.fees = { ...this.form.fees, ...response }
        })
        .catch((err) => {
          throw err
        })
    },
    async storeSelfInstruct() {
      return await axiosService.post('/self-instruct', this.form)
    },
  },
})
