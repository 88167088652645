import axios from 'axios'
import { navigateTo } from 'nuxt/app'
import { getActivePinia } from 'pinia'

export function createBaseService(getHeadersFunction, $baseURL) {
  const service = axios.create({
    baseURL: $baseURL || import.meta.env?.VITE_AGENTS_API_BASE,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  })

  service.interceptors.request.use(
    (config) => {
      config.headers = { ...config.headers, ...getHeadersFunction(config) }
      return config
    },
    (error) => Promise.reject(error),
  )

  service.interceptors.response.use(
    (response) => (response?.data?.version || response.data?.meta ? response.data : response?.data?.data ?? response.data),
    (error) => {
      if (error?.response?.status === 401) {
        getActivePinia()._s.forEach((store) => store.$reset())

        navigateTo('/login')
      }

      // eslint-disable-next-line no-console
      console.error(error, error?.response?.request?.responseURL)
      return Promise.reject(error)
    },
  )
  return service
}
