import { defineStore } from 'pinia'
import axiosService from '~/services/axios.service.js'

export const useGridStore = defineStore(
  'grid',
  () => {
    const grids = ref({})

    const getGrids = () => {
      return axiosService.get('/grids').then((response) => {
        grids.value = response
        return response
      })
    }

    const getGrid = (tableName) => {
      return grids.value[tableName] ?? null
    }

    const createGrid = (tableName, grid) => {
      return axiosService.post('/grids', { owner_key: tableName, data: grid }).then((response) => {
        grids.value[tableName] = { data: grid, owner_key: tableName, id: response.id }
        return response
      })
    }

    const updateGrid = (tableName, grid) => {
      if (!grids.value[tableName]) return createGrid(tableName, grid)

      return axiosService.put(`/grids/${grids.value[tableName].id}`, { data: grid }).then(() => {
        grids.value[tableName] = { ...grids.value[tableName], data: grid }
      })
    }

    const $reset = () => {
      grids.value = {}
    }

    return {
      grids,
      getGrids,
      getGrid,
      createGrid,
      updateGrid,
      $reset,
    }
  },
  { persist: true },
)
