import { defineStore, getActivePinia } from 'pinia'
import axiosOauthService from '~/services/axios.oauth.api.service'
import axiosService from '~/services/axios.service'
import { useAgentStore } from '~/store/agent'
import { useGridStore } from '~/store/grid'

export const useAuthStore = defineStore({
  id: 'auth',
  state: () => ({
    access_token: null,
    refresh_token: null,
    token_type: 'Bearer',
    user: null,
    isProclaimDown: false,
  }),
  getters: {
    authorizationHeader() {
      return `${this.token_type} ${this.access_token}`
    },
    isAuthenticated() {
      return this.access_token !== null
    },
    isInternalUser() {
      return this.user?.internal_user
    },
    isErdi() {
      return this.user?.email === 'ea@avrillo.co.uk'
    },
  },
  actions: {
    setProclaimStatus(isDown) {
      this.isProclaimDown = isDown
    },
    async getToken(formData) {
      return axiosOauthService
        .post(`/oauth/token`, {
          grant_type: 'password',
          client_id: import.meta.env?.VITE_PASSPORT_CLIENT_ID,
          client_secret: import.meta.env?.VITE_PASSPORT_CLIENT_SECRET,
          username: formData.email,
          password: formData.password,
        })
        .then((response) => {
          this.access_token = response.access_token
          this.refresh_token = response.refresh_token
          this.token_type = response.token_type
          this.fetchUser()
        })
    },
    async login(formData) {
      return axiosService
        .post('/login', formData)
        .then((response) => {
          this.access_token = response.access_token
          this.token_type = response.token_type
        })
        .catch((err) => {
          throw err
        })
    },
    async forgotPassword(formData) {
      return axiosService.post('/forgot-password', formData)
    },
    async checkResetPasswordToken(token, email) {
      return axiosService.post(`/reset-password/${token}`, { email: email })
    },
    async resetPassword(formData) {
      return axiosService.post(`/reset-password`, formData)
    },
    async fetchUser() {
      const response = await axiosService.get('/user').catch((err) => {
        throw err
      })
      const agentStore = useAgentStore()
      const gridStore = useGridStore()

      this.user = response
      gridStore.getGrids()

      if (this.isInternalUser) {
        agentStore.estateAgentNames = response.estate_agent_names ?? []
        agentStore.initBranchSelector = agentStore.currentBranch = 'all'
        await agentStore.fetchEstateAgents()
      } else {
        agentStore.branchesFiltered = response.estate_agents.sort((a, b) => a.estate_agent_name.localeCompare(b.estate_agent_name)) ?? []
        agentStore.initBranchSelector = agentStore.branches[0]?.estate_agent_ref ?? 'all'
      }
    },
    async updateUser(formData) {
      return await axiosService
        .put('/user/details', formData)
        .then((response) => {
          this.user = response
        })
        .catch((err) => {
          throw err
        })
    },
    async updatePassword(formData) {
      return await axiosService.put('/user/password', formData)
    },
    async logout() {
      return axiosService.delete(`/logout`).finally(() => {
        getActivePinia()._s.forEach((store) => store.$reset())

        navigateTo('/login')
      })
    },
  },
  persist: true,
})
