import CryptoJS from 'crypto-js'
import { useAgentStore } from '~/store/agent.js'
import { useAuthStore } from '~/store/auth.js'

export const getRequestHeaders = () => ({
  Authorization: useAuthStore().isAuthenticated ? useAuthStore().authorizationHeader : undefined,
  Branches: useAgentStore().branchesListString,
})

export const getRequestHeadersProclaim = (config) => {
  const secret = import.meta.env?.VITE_APP_SIGNING_SECRET
  if (!secret) {
    throw new Error('Signing secret is not defined.')
  }
  const data = config.data
  const content = !data ? '' : JSON.stringify(data)
  return {
    Signature: CryptoJS.HmacSHA256(content, secret).toString(CryptoJS.enc.Hex),
    Authentication: btoa('username:password'),
  }
}

export const getRequestHeadersForms = (config) => {
  const data = config.data
  const reference = config.headers.Reference
  const content = !data || data instanceof FormData ? '' : JSON.stringify(data)
  return {
    Signature: CryptoJS.HmacSHA256(`${content}${reference}`, import.meta.env?.VITE_APP_SIGNING_SECRET).toString(CryptoJS.enc.Hex),
  }
}
