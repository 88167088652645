export function openLink(href) {
  const aTag = document.createElement('a')
  aTag.href = href
  aTag.setAttribute('target', '_blank')
  document.body.appendChild(aTag)
  aTag.click()
  document.body.removeChild(aTag)
}
export function uniqueSortedFromArray(array, field) {
  return [...new Set(array?.map((item) => item[field]).filter(Boolean))].sort()
}
