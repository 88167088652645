<script setup>
import { computed } from 'vue'

const error = useError()

const cause = computed(() => error.value?.cause || {})

const statusCode = computed(() => cause.value.statusCode || 500)

const title = computed(() => cause.value.statusMessage || 'Service Unavailable')

const messages = computed(() => ({
  404: 'The page you are looking for does not exist.',
  500: 'An unexpected error has occurred.',
  503: 'We are currently in maintenance, please try again later.',
}))

const message = computed(() => messages.value?.[statusCode.value] ?? messages.value[500])
</script>

<template>
  <main class="h-screen w-screen">
    <div class="grid min-h-full place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8">
      <div class="text-center">
        <p class="mb-3 text-base font-semibold text-red-500">{{ statusCode }}</p>
        <h1 class="mb-6 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
          {{ title }}
        </h1>
        <p class="text-base leading-7 text-gray-500">
          {{ message }}
        </p>

        <div class="mt-10 flex items-center justify-center gap-6">
          <nuxt-link to="/">
            <el-button size="large" type="primary">Go Back</el-button>
          </nuxt-link>
        </div>
      </div>
    </div>
  </main>
</template>
