import { isEmpty } from 'lodash'
import { defineStore } from 'pinia'
import { uniqueSortedFromArray } from '~/common/helpers.js'
import axiosService from '~/services/axios.service'
import { useAuthStore } from '~/store/auth.js'
import { useSelfInstructStore } from '~/store/self-instruct.js'

export const useAgentStore = defineStore({
  id: 'agent',
  state: () => ({
    negotiators: [],
    estateAgent: {},
    statSummary: {},
    estateAgentNames: [],
    branchesFiltered: [],
    generalSelectors: ['all', 'belongs_to_fee_earner'],
    initBranchSelector: 'all',
    currentBranch: 'all',
    currentEstateAgentName: 'all',
  }),
  getters: {
    feeEarnerRef() {
      return useAuthStore().user?.fee_earner_ref
    },
    areAllAgentsAndAllBranchesSelected() {
      return this.currentEstateAgentName === 'all' && this.currentBranch === 'all'
    },
    branches() {
      return this.branchesFiltered.filter((branch) => {
        if (this.currentBranch === 'all') return true
        if (this.currentBranch === 'belongs_to_fee_earner') return branch.fee_earner_ref === this.feeEarnerRef
        return branch.estate_agent_ref === this.currentBranch
      })
    },
    filterByFeeEarnerRef() {
      return this.currentBranch === 'all' && this.currentEstateAgentName !== 'all' ? useAuthStore().user?.fee_earner_ref : null
    },
    branchesListString() {
      const branchesList = uniqueSortedFromArray(this.branches, 'estate_agent_ref').join(',')

      return isEmpty(branchesList) ? 'all' : branchesList
    },
    selectedEstateAgentNamesString() {
      return this.currentEstateAgentName === 'all' ? this.estateAgentNames?.slice(0, 200)?.join(',') : this.currentEstateAgentName
    },
    isEstateAgentNameSet() {
      return !this.isGeneralSelector(this.currentEstateAgentName)
    },
    estateAgentName() {
      return this.isEstateAgentNameSet ? this.currentEstateAgentName : ''
    },
    belongsToFeeEarner() {
      return this.currentBranch === 'belongs_to_fee_earner'
    },
    amlSaleVisible() {
      return this.branches?.filter((branch) => branch.can_request_id_check === true)?.length > 0
    },
    amlPurchaseVisible() {
      return this.branches?.filter((branch) => branch.can_request_purchase_id_check === true)?.length > 0
    },
    useAltIdFlow() {
      return this.branches?.filter((branch) => branch.use_alt_id_flow === true)?.length > 0
    },
  },
  actions: {
    hasFeeEarnerBrunches(branches) {
      return branches.some((branch) => branch.fee_earner_ref === this.feeEarnerRef)
    },
    isGeneralSelector(agentName) {
      return this.generalSelectors.includes(agentName)
    },
    async fetchNegotiators() {
      return await axiosService
        .get('/negotiators')
        .then((response) => {
          this.negotiators = response
        })
        .catch((err) => {
          throw err
        })
    },
    async fetchEstateAgent(estateAgent) {
      return await axiosService
        .get(`/estate-agents/${estateAgent}`)
        .then((response) => {
          const selfInstructStore = useSelfInstructStore()
          this.estateAgent = response
        })
        .catch((err) => {
          throw err
        })
    },
    async updateEstateAgent(estateAgent, formData) {
      return await axiosService.put(`/estate-agents/${estateAgent}`, formData).catch((err) => {
        throw err
      })
    },
    async fetchEstateAgents() {
      if (!this.filterByFeeEarnerRef && !this.selectedEstateAgentNamesString) return
      return await axiosService
        .get('/estate-agents', {
          params: {
            fee_earner_ref: this.filterByFeeEarnerRef,
            estate_agent_names: this.selectedEstateAgentNamesString,
          },
        })
        .then((response) => {
          this.branchesFiltered = response.sort((a, b) => a.estate_agent_name.localeCompare(b.estate_agent_name))
          this.currentBranch =
            response?.length === 1 ? response[0].estate_agent_ref : this.hasFeeEarnerBrunches(response) ? this.initBranchSelector : 'all'
        })
        .catch((err) => {
          throw err
        })
    },
    async fetchAddress(address) {
      return await axiosService.get(`/address-finder/address?address=${address}`).catch((err) => {
        throw err
      })
    },
    async fetchUDPRN(udprn) {
      return await axiosService.get(`/address-finder/udprn?udprn=${udprn}`).catch((err) => {
        throw err
      })
    },
  },
  persist: true,
})
